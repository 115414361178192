<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow menu-under-navbar"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded" :style="{ height: isVerticalMenuCollapsed ? '0' : 'auto' }">
      <ul class="nav navbar-nav flex-row">
        <!-- Menu Title -->
        <li class="nav-item mr-auto">
          <div class="d-flex align-items-center h-100">
            <p v-show="!isVerticalMenuCollapsed" class="font-weight-bold mb-0 ml-1 h4">
              {{ $t('settings.menu.title') }}
            </p>
          </div>
        </li>

        <!-- Toggler Button -->
        <li class="nav-item nav-toggle">
          <b-link class="nav-link modern-nav-toggle d-flex">
            <feather-icon
              icon="XIcon"
              size="16"
              class="d-block d-xl-none"
              @click="toggleVerticalMenuActive"
            />
            <feather-icon
              :icon="isVerticalMenuCollapsed ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
              size="16"
              class="d-none d-xl-block collapse-toggle-icon"
              @click="toggleCollapsed"
            />
          </b-link>
        </li>
      </ul>
    </div>

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <VuePerfectScrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <div class="direct-links-container">
        <div class="draggable">
          <div class="vertical-nav-menu-header">
            <!-- Add debug info -->

            <ul class="navigation navigation-main">
              <!-- Collapsed Menu Toggler -->
              <li v-if="isVerticalMenuCollapsed" class="my-25">
                <b-link
                  class="nav-link d-flex align-items-center justify-content-center"
                  @click="toggleCollapsed"
                >
                  <feather-icon
                    :icon="'ChevronRightIcon'"
                    class="collapsed-toggle-icon"
                  />
                </b-link>
              </li>

              <li
                v-for="(item, index) in bancolombiaItemsArray"
                :key="`${item.title}-${index}`"
                class="nav-item my-25 special-nav-item"
                :class="{
                  'sidebar-group-active active': isActiveItem(item),
                  'menu-collapsed': isVerticalMenuCollapsed
                }"
              >
                <b-link
                  class="nav-link d-flex align-items-center"
                  :class="{
                    'bg-light rounded':
                      item.title === $t('backoffice.back') || item.title === 'Back' || item.title === 'Home',
                    'justify-content-center': isVerticalMenuCollapsed,
                    'justify-content-start': !isVerticalMenuCollapsed
                  }"
                  :to="item.route"
                >
                  <img
                    :src="item.icon"
                    width="20"
                    height="20"
                    :alt="item.title"
                  >
                  <span v-show="!isVerticalMenuCollapsed" class="ml-25">
                    {{ translate(item.title) }}
                  </span>
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import { BLink, BBadge, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
// import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import useVerticalNavMenu from './useVerticalNavMenu';

// import VuePerfectScrollbar from 'vue-perfect-scrollbar';
// import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
// import VerticalNavMenuItemsStructure from './components/vertical-nav-menu-items-structure/VerticalNavMenuItemsStructure.vue';

export default {
  name: 'VerticalNavMenu',

  components: {
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar' /* webpackChunkName: "VuePerfectScrollbar" */),
    VerticalNavMenuItems: () => import('./components/vertical-nav-menu-items/VerticalNavMenuItems.vue' /* webpackChunkName: "VerticalNavMenuItems" */),
    VerticalNavMenuItemsStructure: () => import('./components/vertical-nav-menu-items-structure/VerticalNavMenuItemsStructure.vue' /* webpackChunkName: "VerticalNavMenuItemsStructure" */),
    // CollectiveLogo,
    BLink,
    BImg,
    BBadge,
  },
  mixins: [GridStatusMixin],
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
    appsInMenu: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    isBackoffice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenu: true,
      onlyStructure: false,
    };
  },
  computed: {
    isCommunity() {
      // This information will not come out if we are in my-netios
      return this.$route?.params?.communityId || this.$route?.params?.communitySlug;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    parentCollective() {
      return this.$store.getters?.parentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    isMobile() {
      return this.isGridDisabled;
    },
    allCommunities() {
      const allCommunities = [];
      if (
        this.mainCollective != null
        && this.mainCollective.slug != null
        && this.mainCollective.slug !== this.collective.slug
      ) {
        allCommunities.push(this.mainCollective);
      }
      if (
        this.parentCollective != null
        && this.parentCollective?.slug != null
        && this.mainCollective?.slug !== this.parentCollective?.slug
      ) {
        allCommunities.push(this.parentCollective);
        for (let i = 0; i < 100; i++) {
          if (allCommunities[1].parentCollective
          && allCommunities[1].parentCollective?.key !== allCommunities[0].key) {
            const newComunity = allCommunities[1].parentCollective;
            allCommunities.splice(1, 0, newComunity);
          } else {
            break;
          }
        }
      }
      allCommunities.push(this.collective);
      // console.log('allCommunities', allCommunities);
      return allCommunities;
    },
    spacesTreeData() {
      return this.allCommunities.map((community) => ({
        title: community.name,
        slug: community.slug,
        isMain: community.mainCommunityID === null,
        // icon: community.logoURL,
        route: {
          name: 'Resumen',
          params: {
            communityId: community.slug,
          },
        },
      }));
    },
    hasStructure() {
      return this.collective.communityMain && this.collective.parentKey && this.collective.communityMain !== this.collective.parentKey;
    },
    bancolombiaItemsArray() {
      return [
        {
          title: "Inicio",
          icon: "/assets/customers/nexos/icons/icono-inicio.svg",
          route: {
            name: 'Resumen',
            params: {
              communityId: "nexos",
            },
          },
        },
        {
          title: "Perfil",
          icon: "/assets/customers/nexos/icons/icono-perfil.svg",
          route: {
            name: 'memberProfile',
            params: {
              communityId: "nexos",
            },
          },
        },
        {
          title: "Laboratorio de ideas",
          icon: "/assets/customers/nexos/icons/icono-construyamos.svg",
          route: {
            name: 'Resumen',
            params: {
              communityId: "construyamos-juntos",
            },
          },
        },
        {
          title: "Conócenos",
          icon: "/assets/customers/nexos/icons/icono-conocenos.svg",
          route: {
            name: 'Resumen',
            params: {
              communityId: "conocenos",
            },
          },
        },
        // {
        //   title: "Hablemos",
        //   icon: "/assets/customers/nexos/icons/icono-hablemos.svg",
        //   route: {
        //     name: 'Resumen',
        //     params: {
        //       communityId: "hablemos",
        //     },
        //   },
        // },
        // {
        //   title: "¿Tienes dudas?",
        //   icon: "/assets/customers/nexos/icons/icono-dudas.svg",
        //   route: {
        //     name: 'Resumen',
        //     params: {
        //       communityId: "tienes-dudas",
        //     }
        //   }
        // },
      ];
    },
  },
  watch: {
    $route(to, from) {
      // React to route changes if necessary
      // this only for routes that are in the array bancolombiaItemsArray
      const isBancolombiaItem = this.bancolombiaItemsArray.some((item) => item.route.name === to.name);
      if (isBancolombiaItem) {
        this.handleRouteChange(to, from);
      }
    },
  },
  created() {
    // console.log('VerticalNavMenu.vue')
    if (this.appsInMenu && this.appsInMenu.length <= 1 && this.hasStructure && this.isMobile) {
      this.isMenu = false;
      this.onlyStructure = true;
    }
  },
  methods: {
    getImage(collective) {
      if (collective.logoURL != null) {
        return getImageResource(collective.logoURL);
      }
      if (collective.header != null && collective.header.logo_url != null) {
        return getImageResource(collective.header?.logo_url);
      }
    },
    translate(field) {
      return translateTranslationTable(this.locale, field);
    },
    isActiveItem(item) {
      // console.log('this.spacesTreeData', this.spacesTreeData);
      // console.log('item.route', item.route);
      // console.log('this.$route', this.$route);
      const tabIsActiveName = item.route.name === this.$route.name;
      const tabIsActiveParams = item.route.params.communityId === this.$route.params.communityId;

      const tabIsInSpacesTree = this.spacesTreeData.some((space) => space.slug === item.route.params.communityId && !space.isMain);

      const tabIsActive = (tabIsActiveParams && tabIsActiveName) || (tabIsInSpacesTree);
      // console.log('tabIsActive', tabIsActive);
      return tabIsActive;
    },
    handleRouteChange(to, from) {
      // Handle route change logic here
      // console.log('Route changed from', from, 'to', to);
      // Redirect to the route
      // this.$router.replace(to);
      window.location.reload();

      // TODO: Remove the window.location.reload()
      // Only reload if absolutely necessary for specific route changes
      // if (to.params.communityId !== from.params.communityId) {
      //   // Handle specific cases where reload is needed
      //   window.location.reload();
      // }
    },

  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() => (
      collapseTogglerIcon.value === 'unpinned' ? 'ChevronUpIcon' : 'ChevronDownIcon'
    ));

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.menu-under-navbar {
  top: 57px;
}

.direct-links-container {
  padding: 1rem;
  margin-top: 0;
}

.special-nav-item {
  &:hover {
    background-color: #f8f9fa !important;
    border-radius: 5px;
  }

  .nav-link {
    padding: 0.5rem;
    font-weight: bold;
    min-width: 40px; // Add minimum width for icon container
    transition: all 0.3s ease;

    img {
      min-width: 20px;
    }
  }

  // Add new styles for active state
  &.sidebar-group-active.active {
    background-color: #fdda25 !important; // Light yellow background
    border-radius: 8px;

    .nav-link {
      border-radius: 8px;
    }
  }

  &.menu-collapsed {
    .nav-link {
      justify-content: center !important;
    }
  }
}

.navbar-header .h4 {
  color: #000 !important;
}

// Updated toggle icon styles
.collapse-toggle-icon {
  color: #000 !important;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #e9ecef;
  }
}

.collapsed-toggle-icon {
  color: #000 !important;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  width: 36px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px !important;

  &:hover {
    background-color: #e9ecef;
  }
}

// Add transition for menu collapse
.main-menu {
  transition: width 0.3s ease;

  &.menu-collapsed {
    .nav-link {
      justify-content: center !important;
      padding: 0.5rem !important;
    }
  }
}

</style>
