var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow menu-under-navbar",class:[
    { 'expanded': !_vm.isVerticalMenuCollapsed },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]},[_c('div',{staticClass:"navbar-header expanded",style:({ height: _vm.isVerticalMenuCollapsed ? '0' : 'auto' })},[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isVerticalMenuCollapsed),expression:"!isVerticalMenuCollapsed"}],staticClass:"font-weight-bold mb-0 ml-1 h4"},[_vm._v(" "+_vm._s(_vm.$t('settings.menu.title'))+" ")])])]),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle d-flex"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.isVerticalMenuCollapsed ? 'ChevronRightIcon' : 'ChevronLeftIcon',"size":"16"},on:{"click":_vm.toggleCollapsed}})],1)],1)])]),_c('div',{staticClass:"shadow-bottom",class:{'d-block': _vm.shallShadowBottom}}),_c('VuePerfectScrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) { _vm.shallShadowBottom = evt.srcElement.scrollTop > 0 }}},[_c('div',{staticClass:"direct-links-container"},[_c('div',{staticClass:"draggable"},[_c('div',{staticClass:"vertical-nav-menu-header"},[_c('ul',{staticClass:"navigation navigation-main"},[(_vm.isVerticalMenuCollapsed)?_c('li',{staticClass:"my-25"},[_c('b-link',{staticClass:"nav-link d-flex align-items-center justify-content-center",on:{"click":_vm.toggleCollapsed}},[_c('feather-icon',{staticClass:"collapsed-toggle-icon",attrs:{"icon":'ChevronRightIcon'}})],1)],1):_vm._e(),_vm._l((_vm.bancolombiaItemsArray),function(item,index){return _c('li',{key:((item.title) + "-" + index),staticClass:"nav-item my-25 special-nav-item",class:{
                'sidebar-group-active active': _vm.isActiveItem(item),
                'menu-collapsed': _vm.isVerticalMenuCollapsed
              }},[_c('b-link',{staticClass:"nav-link d-flex align-items-center",class:{
                  'bg-light rounded':
                    item.title === _vm.$t('backoffice.back') || item.title === 'Back' || item.title === 'Home',
                  'justify-content-center': _vm.isVerticalMenuCollapsed,
                  'justify-content-start': !_vm.isVerticalMenuCollapsed
                },attrs:{"to":item.route}},[_c('img',{attrs:{"src":item.icon,"width":"20","height":"20","alt":item.title}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isVerticalMenuCollapsed),expression:"!isVerticalMenuCollapsed"}],staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.translate(item.title))+" ")])])],1)})],2)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }